import * as React from "react";
import { Link } from "gatsby";
import Container from "../../components/container";
import Layout from "../../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  description,
  row,
  // col6,
  col12,
  // marginTopSubstitute,
  hubSpotForm,
} from "../subpage.module.scss";

const JahresendVerlosungPage = () => {
  return (
    <Layout pageTitle="Jahresendverlosung 2022" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>TIE Jahresendgewinnspiel 2022</p>
                <h1>Gewinnen Sie einen Gutschein von einem halben Tag Konfig. </h1>
                <p className="text-medium">
                Wir sind stolz darauf, auf unsere Kunden zählen zu können und möchten uns einmal mehr 
                für das entgegengebrachte Vertrauen und die Treue bedanken. Wir freuen uns auf die gemeinsame Zukunft und 
                sind stets für Sie da. </p>
            

            <div className={row}>
              <div className={col12}>
                <h3>
                Einfach gesagt und getan: Das Formular unten ausfüllen und an der Verlosung teilnehmen.
                </h3>
                <p>
                Als kleines Dankeschön an unsere Kundschaft verschenken wir zum Jahresende 3 Gutscheine für einen halben Tag Konfig (à 4h). Die Teilnahme an der Verlosung ist ab 
                heute (15. November 2022) möglich.<span className="text-blue"> Teilnahmeschluss: 12. Dezember 2022, 12:00 Uhr.</span></p>
                
                <p>Die drei glücklichen Gewinnenden werden am 13. Dezember von uns ausgelost und im Verlauf des Tages per Telefon/Mail kontaktiert. Hier finden Sie unsere<Link to="/landingpages/teilnahmbedienungen/"> Teilnahmbedienungen.</Link> </p>

                <p> Alle Mitarbeitenden eines Kundenunternehmens dürfen einmal teilnehmen und damit die Chancen des eigenen Unternehmens auf einen Gewinn steigern. Je Unternehmen ist maximal ein Gewinn möglich.</p>

                <p>Im Namen des gesamten <span className="text-blue"> the i-engineers Team </span>, wünschen wir Ihnen und Ihren Angehörigen frohe Festtage und viel Glück, Gesundheit und Erfolg im neuen Jahr. </p>
                
                 </div>

            
            </div>
            </div>
            </div>
            
            <div className={row}>
              <div className={col12}>
                <h3>Jetzt im Verlosungstopf landen</h3>
                <p>Sie können sich auch gerne über diese E-Mail Adresse anmelden: <a href="mailto:insight@tie.ch">insight(a)tie.ch</a>  Bitte inkludiert im E-Mail Ihren vollen Namen mit Firmennamen. </p>
                <div className={hubSpotForm}>
                  <HubspotForm
                    portalId="9456519"
                    formId="411dfe36-1652-480a-8dfe-2c61c7899059"
                    region="na1"
                    onSubmit={() => console.log("Submit!")}
                    // onReady={(form) => console.log("Form ready!")}
                    onReady={() => {
                      const script = document.createElement("script");
                      script.defer = "defer";
                      script.src =
                        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
                      let jqueryScript =
                        document.getElementsByTagName("script");
                      let src = Array.from(jqueryScript).filter(
                        (item) => item.src === script.src
                      );
                      if (src.length === 0) {
                        document.body.appendChild(script);
                      }
                    }}
                    loading={<p>Formular wird geladen...</p>}
                  />
                </div>
              </div>
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default JahresendVerlosungPage;
